import React from "react"
import loadable from '@loadable/component'

import SetsUsApart from "../components/SetsUsApart"
import Showcase from "../components/Showcase"
import Testimonials from "../components/Testimonials"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Hero from "../components/MainHero"
import MainHero from "../components/MainHero"
import keywords from "../constants/keywords"
import LoadableParallax from "../components/parallax/homeParallax"

import About from '../components/About';
import Video from '../components/MainVideo';
import BuildSolutionHero from '../components/BuildPage/BuildSolutionHero'
import BuildSolutionBullets from "../components/BuildPage/BuildSolutionBullets"
import BuildSolutionSoftware from "../components/BuildPage/BuildSolutionSoftware"
import BuildSolutionCloud from "../components/BuildPage/BuildSolutionCloud"
import BuildSolutionAutomation from "../components/BuildPage/BuildSolutionAutomation"
import ProcessCards from "../components/OurProcess"


// const LoadableParallax = loadable(() => import('../components/parallax/homeParallax'))

const SomethingNew = () => {

  // let keywords = keywords.home.keywords
  // console.log(keywords.home.keywords)
  return (
    <>

      <Layout black={false}>

        <SEO
          title={"Build Your Idea | Differnt Systems"}
          description={"Build Your Idea with the Right Team. Full ownership, flat-rate pricing, and ongoing support. Craft apps, improve systems, and automate for efficiency."}
          customKeywords={keywords.home.keywords}
        />
        <div className="offcanvas-wrap">
          <BuildSolutionHero/>
          <BuildSolutionBullets/>
          <BuildSolutionSoftware/>
          <BuildSolutionCloud/>
          <BuildSolutionAutomation/>
          <ProcessCards/>
          {/* <LoadableParallax /> */}
        </div>
      </Layout>

    </>
  )
}

export default SomethingNew

import React from 'react';
import { Helmet } from "react-helmet"
import arrowRight from "../../assets/images/button_arrow.png"
import featureImage from "../../assets/images/BuildImages/Group-of-People-Walking-Into-Modern-Room.jpg"
import featureVideo1 from "../../assets/images/feature-video1.jpg"
import featureVideo2 from "../../assets/images/feature-video2.jpg"
import BtnArrow from "../../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../../assets/images/button_arrowbottom.svg"

function BuildSolutionAutomation(props) {
    return (
        <>
            <Helmet>
                {/* <script src="https://fast.wistia.com/embed/medias/7za409l7ze.jsonp" async></script>
                <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script> */}
            </Helmet>
            <section class="inverted mx-xl-3 feature-video">
            <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                           <img src={featureImage} alt='Group of People Walking Into Modern Room'/> 
                        </div>
                        <div className='col-md-6'>
                            <div className='feature-right'>
                                <span className='sub-title'>Process Automation Services</span>
                                <h2>Give That ‘Wow’ Impression.</h2>
                                <p>Offer your guests and employees efficiency advantages through advanced technologies, creating a frictionless environment that keeps them engaged. With expertise in high-touch, high-volume environments, we ensure flawless deployments.</p>
                                 <ul className='list-icon'>
                                    <li>Reallocate Staff To High-Impact Positions</li>
                                    <li>Create Experiences Your Guests Look Forward To</li>
                                    <li>Create Custom Systems That Fit Your Exact Needs</li>
                                    
                                </ul>   
                                <a href='/automation-services/' className='button-link'>Explore Automation Services 
                                <div className="arrow_movement">
                                        <img src={BtnArrow} alt=''/>
                                        <img src={BtnArrowbottom} alt=''/>
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                
                </div>
               
            </section>
        </>
    );
}

export default BuildSolutionAutomation;
import React from 'react';
import { Helmet } from "react-helmet"
import arrowRight from "../../assets/images/button_arrow.png"
import featureImage from "../../assets/images/BuildImages/Two-Men-Standing-Near-Laptop-On-Table.jpg"
import BtnArrow from "../../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../../assets/images/button_arrowbottom.svg"


function BuildSolutionCloud(props) {
    return (
        <>
            <Helmet>
                {/* <script src="https://fast.wistia.com/embed/medias/7za409l7ze.jsonp" async></script>
                <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script> */}
            </Helmet>
            <section class="inverted mx-xl-3 solution-cloud">
            <div className='container'>
                    <div className='row align-items-center'>
                        
                        <div className='col-md-6'>
                            <div className='feature-right'>
                                <span className='sub-title'>Managed Cloud & Cybersecurity</span>
                                <h2>Scale Without Worry.</h2>
                                <p>Managing critical systems in the cloud, from monitoring to scaling, can be complex. As cybersecurity requirements become increasingly intricate across every industry, our team of experts make the cloud magical again.</p>
                                 <ul className='list-icon'>
                                    <li>Fully Managed Team to Support Your Growth</li>
                                    <li>Multiple Options That Mitigate Vendor-Lock</li>
                                    <li>Cost-Effective Options for All Budgets</li>
                                    
                                </ul>   
                                <a href='/cloud-services/' className='button-link'>Explore Cloud Services
                                    <div className="arrow_movement">
                                            <img src={BtnArrow} alt=''/>
                                            <img src={BtnArrowbottom} alt=''/>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-md-6'>
                           <img src={featureImage} alt='Two men standing near laptop on table'/> 
                        </div>
                    </div>
                
                </div>
               
            </section>
        </>
    );
}

export default BuildSolutionCloud;
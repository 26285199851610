import React, { useEffect } from 'react';
import Check from "../../assets/images/svg/icons/Box-Checked-Icon.svg"
import Money from "../../assets/images/svg/icons/Money-Icon.svg"
import Support from "../../assets/images/svg/icons/Support-Icon.svg"




function BuildSolutionBullets() {

    {/* header */ }
    return (

        <div className='inverted'>
            <canvas className="orb-canvas" data-aos="fade-in" />
            <>
               <section className='digital-solution'>
                    <div className='container'>
                        <div className='solution-top'>
                            <h2>The <span className='text-green'>Right Team</span>  For<br></br>The Next Big Idea.</h2>
                            <p>Our reputation hinges on exceptional deliverability to clients. We recruit top industry talent from diverse sources to ensure excellence. With a vast talent pool, we assemble the right team to flawlessly execute your vision — guaranteed.</p>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='solution-box'>
                                    <span className='icon'><img src={Check} alt='Box Checked'/></span>
                                    <h4>Complete Ownership</h4>
                                    <p>Full intellectual property remains with you. We operate under strict NDAs and do not take equity stakes.</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='solution-box'>
                                    <span className='icon'><img src={Money} alt='Money'/></span>
                                    <h4>Flat-Rate Pricing</h4>
                                    <p>Our proprietary pricing algorithm guarantees a flat-rate structure, fostering mutual investment for success.</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='solution-box'>
                                    <span className='icon'><img src={Support} alt='Support Headphone'/></span>
                                    <h4>Continued Support</h4>
                                    <p>Beyond our industry-leading warranties, we offer ongoing support plans to further scale your vision.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
             
            </> 

            
            

          
        </div>
    );
}



export default BuildSolutionBullets;